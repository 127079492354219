<template>
  <asterix-form-modal
    v-if="creative"
    :title="modalTitle"
    closable
    modal-class="sm:max-w-3xl"
    @submit="submitForm"
    @keypress.enter="submitForm"
    @cancel="$emit('cancel')"
  >
    <template #content>
      <div class="content-height overflow-auto pb-6 px-3">
        <card-form v-if="showTemplate">
          <template #title> Template</template>
          <template #form>
            <form-row>
              <template #left>
                <sun-label-group text="Title">
                  <asterix-input
                    id="template-title"
                    v-model="template.name"
                    type="text"
                    name="title"
                    :minlength="3"
                    :maxlength="50"
                    validate-on-blur
                    placeholder="A title..."
                    required="required"
                  />
                </sun-label-group>
              </template>
              <template #right>
                <sun-label-group text="Description">
                  <sun-text-area
                    id="template-description"
                    :value="template.description"
                    type="text"
                    name="description"
                    :minlength="3"
                    :maxlength="100"
                    placeholder="A description..."
                    @change="template.description = $event.value"
                  />
                </sun-label-group>
              </template>
            </form-row>
          </template>
        </card-form>
        <card-form>
          <template #title> Info</template>
          <template #form>
            <form-row>
              <template #left>
                <sun-label-group text="Url">
                  <asterix-input
                    id="creative-url"
                    v-model="creative.link"
                    type="url"
                    name="url"
                    validate-on-blur
                    placeholder="A url..."
                    required="required"
                  />
                </sun-label-group>
              </template>
              <template #right>
                <sun-label-group text="Status">
                  <sun-select
                    :value="creative.status"
                    :options="statusOptions"
                    track-by="value"
                    label="name"
                    name="status"
                    close-on-select
                    required="required"
                    text-error="the field is required."
                    add-hex-color="orange"
                    @input="creative.status = $event"
                  />
                </sun-label-group>
              </template>
            </form-row>
          </template>
        </card-form>
        <card-form>
          <template #title> File *</template>
          <template #form>
            <sun-alert :dismissible="false" variant="info">
              <template #title> Recommended image sizes </template>
              <template #description>
                <ul class="list-style-inside columns-2">
                  <li v-for="(size, index) in recommendedSizes" :key="index">{{ size }}</li>
                </ul>
              </template>
            </sun-alert>
            <form-row>
              <sun-file-uploader
                :accept="acceptedFiles"
                @change="clickFile"
                @reset="deleteFile"
                @delete-file="deleteFile"
                @error="wrongFile"
              />
            </form-row>
            <SunErrorText v-show="fileError" show-error text-error="Invalid file"></SunErrorText>
            <creative-preview v-if="creative.urlFile" class="mt-8">
              <img alt="creative image" :src="creative.urlFile" />
            </creative-preview>
          </template>
        </card-form>
        <card-form>
          <template #title> Impression Path (Pixel) </template>
          <template #form>
            <code-editor
              :value="creative.pixel"
              :internal-error="pixelError"
              text-error="Invalid pixel"
              @blur="pixelValidation($event)"
              @change="pixelValidation($event)"
            ></code-editor>
          </template>
        </card-form>
      </div>
    </template>
    <template #footer>
      <div class="flex flex-wrap justify-between sm:px-3">
        <save-button
          id="creative-submit"
          :loading="isLoading"
          variant="pill"
          class="w-full text-sm custom-p-1 sm:w-auto"
        />
        <sun-button variant="pill" class="w-full text-sm custom-p-1 sm:w-auto" color="white" @click="$emit('cancel')">
          Cancel
        </sun-button>
      </div>
    </template>
  </asterix-form-modal>
</template>

<script>
import { mapActions } from 'vuex';
import CardForm from '@/components/atoms/CardForm';
import SaveButton from '@/components/atoms/SaveButton';
import FormRow from '@/components/atoms/FormRow/FormRow';
import AsterixInput from '@/components/atoms/AsterixInput';
import CodeEditor from '@/components/atoms/CodeEditor.vue';
import AsterixFormModal from '@/components/organisms/shared/AsterixFormModal';
import CreativeNative from '@/entities/socialAudience/CreativeNative';
import { getCreativeById, createCreative, updateCreative } from '@/services/modules/socialAudience/creative/native';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import TemplateCreative from '@/model/modules/socialAudience/creative/TemplateCreative';
import { creative } from '@/router/private/modules/socialAudience/demand/campaign/editForm/creative';
import CreativePreview from '@/components/organisms/shared/creatives/CreativePreview';
import { CREATIVE_STATUS } from '@/model/modules/socialAudience/creative/CreativeStatus';
import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';
import CONFIG from './config';

export default {
  name: 'CreativeModal',
  components: {
    CardForm,
    SaveButton,
    FormRow,
    AsterixInput,
    CodeEditor,
    AsterixFormModal,
    CreativePreview,
  },
  mixins: [],
  props: {
    creativeId: { type: String, default: () => null },
    insertionId: { type: String, default: () => null },
    showTemplate: { type: Boolean, default: false },
  },
  data: () => ({
    isLoading: false,
    creative: null,
    template: new TemplateCreative(),
    acceptedFiles: '.png, .jpg, .gif',
    // eslint-disable-next-line
    expression: /.*https:\/\/.*(moatads.com|adsafeprotected.com|scorecard.com).*>/i,
    pixelError: false,
    fileError: false,
    validForm: false,
    statusOptions: CREATIVE_STATUS,
    recommendedSizes: CONFIG.recommendedSizes,
  }),
  computed: {
    modalTitle() {
      return !!this.creativeId && !!this.creative && this.creative?.title !== null
        ? `Edit ${this.creative.title}`
        : 'New Creative';
    },
  },
  watch: {
    creativeId: 'getCreativeById',
  },
  async created() {
    if (this.creativeId) {
      await this.getCreativeById(this.creativeId);
    } else {
      this.creative = new CreativeNative();
    }
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
    }),
    async getCreativeById(creativeId) {
      try {
        const { data } = await getCreativeById(creativeId);
        this.creative = data;
      } catch (error) {
        if (error.code !== HTTPStatusCode.Cancel) {
          this.createToast(Toast.error(`Can't get creative.`, error.message));
        }
      }
    },
    async submitForm(value) {
      this.checkFields();
      this.formValidate(value);

      if (!this.validForm) return;

      this.isLoading = true;
      if (this.showTemplate) this.$emit('new-template', this.template);
      if (this.creativeId) {
        await this.updateCreative();
      } else {
        await this.createCreative();
      }
      this.isLoading = false;
    },
    async createCreative() {
      try {
        await createCreative(this.creative, this.insertionId);
        this.createToast(Toast.success('Creative created', `The creative was created successfully.`));
      } catch (error) {
        this.createToast(Toast.error('Creative not created', error.message));
      } finally {
        await this.goToCreativesList();
      }
    },
    async updateCreative() {
      try {
        await updateCreative(this.creative);
        this.createToast(Toast.success('Creative updated', 'The creative was updated successfully'));
      } catch (error) {
        this.createToast(Toast.error('Creative not updated', error.message));
      } finally {
        await this.goToCreativesList();
      }
    },
    checkFields() {
      this.fileError = !this.creative.file && !this.creative.urlFile;
    },
    formValidate(form) {
      if (!form.valid) return false;
      if (this.pixelError) return false;
      if (this.fileError) return false;

      this.validForm = true;
    },
    pixelValidation(value) {
      const pixel = value.trim();
      this.creative.pixel = pixel;

      const pixelElements = pixel.split('<');
      this.pixelError = !!pixelElements.find(element => !!element.trim() && !this.expression.test(element));
    },
    clickFile(files) {
      this.creative.file = files?.[0];
      this.creative.urlFile = this.creative.file ? URL.createObjectURL(this.creative.file) : null;
      this.fileError = false;
    },
    deleteFile() {
      this.creative.file = null;
      this.creative.urlFile = null;
    },
    wrongFile() {
      this.fileError = true;
    },
    async goToCreativesList() {
      this.$emit('update');
      await this.$router.push(creative);
    },
  },
};
</script>

<style scoped>
.content-height {
  height: 66vh;
}

::v-deep #creative-submit {
  @apply ml-0;
}

.columns-2 {
  columns: 2;
}

.list-style-inside {
  list-style: inside;
}
</style>
