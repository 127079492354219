var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("svg", { attrs: { viewBox: "0 0 512 512" } }, [
    _c("path", {
      staticClass: "fill-current",
      attrs: {
        d: "M298.7 213.3V0h-85.4v213.3H0v85.4h213.3V512h85.4V298.7H512v-85.4z",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }