<template>
  <asterix-form-modal
    v-if="creative"
    closable
    modal-class="sm:max-w-3xl"
    :title="modalTitle"
    @submit="submitForm"
    @keypress.enter="submitForm"
    @cancel="$emit('cancel')"
  >
    <template #content>
      <div class="content-height overflow-auto pb-6 px-3">
        <card-form>
          <template #title> Info</template>
          <template #form>
            <form-row>
              <template #left>
                <sun-label-group text="Name">
                  <asterix-input
                    v-model="creative.name"
                    auto-validate
                    :maxlength="50"
                    :minlength="1"
                    name="name"
                    placeholder="A name..."
                    required="required"
                    type="text"
                  />
                </sun-label-group>
              </template>
              <template #right>
                <sun-label-group text="Status">
                  <sun-select
                    :value="creative.status"
                    :options="statusOptions"
                    track-by="value"
                    label="name"
                    name="status"
                    close-on-select
                    required="required"
                    text-error="This field is required"
                    add-hex-color="orange"
                    @input="creative.status = $event"
                  />
                </sun-label-group>
              </template>
            </form-row>
          </template>
        </card-form>
        <card-form>
          <template #title> File </template>
          <template #form>
            <form-row>
              <sun-label-group class="w-full" text="Creative">
                <sun-select
                  :value="creative.typeCreative"
                  :options="creativeTypeOptions"
                  track-by="id"
                  label="name"
                  name="status"
                  close-on-select
                  required="required"
                  text-error="This field is required"
                  add-hex-color="orange"
                  @input="changeCreativeType"
                />
              </sun-label-group>
            </form-row>
            <form-row v-if="isCreativeTypeFile">
              <sun-alert :dismissible="false" variant="info">
                <template #description>
                  Please provide a video file to configure the ad. The maximum size for uploaded file is 500Mb.
                </template>
              </sun-alert>
            </form-row>
            <form-row v-if="isCreativeTypeFile">
              <sun-label-group text="File" class="w-full">
                <sun-file-uploader
                  :accept="acceptedFiles"
                  :max-size="maxVideoSize"
                  @change="clickFile"
                  @reset="deleteFile"
                  @delete-file="deleteFile"
                  @error="wrongFile"
                />
              </sun-label-group>
            </form-row>
            <SunErrorText v-if="fileError" show-error text-error="Invalid file"></SunErrorText>
            <creative-preview v-if="creative.urlFile" class="mt-4 mb-4">
              <video width="320" height="240" controls>
                <source :src="creative.urlFile" type="video/mp4" />
              </video>
            </creative-preview>
            <form-row v-if="isCreativeTypeFile">
              <sun-label-group class="w-full" text="Destination url">
                <asterix-input
                  id="destination-url"
                  v-model="creative.destinationUrl"
                  type="url"
                  name="destinationUrl"
                  validate-on-blur
                  placeholder="A url..."
                  required="required"
                />
              </sun-label-group>
            </form-row>
            <form-row v-if="isCreativeTypeVast">
              <sun-alert :dismissible="false" variant="info">
                <template #description>
                  Please configure a URL to an external ad server to enable 3rd party ad serving. The URL must lead to a
                  valid VAST 2.0 XML response.
                </template>
              </sun-alert>
            </form-row>
            <form-row v-if="isCreativeTypeVast">
              <sun-label-group class="w-full" text="3rd  Party Ad URL (VAST)">
                <asterix-input
                  id="vast"
                  v-model="creative.vast"
                  type="url"
                  name="vast"
                  validate-on-blur
                  placeholder="A vast..."
                  required="required"
                />
              </sun-label-group>
            </form-row>
          </template>
        </card-form>
        <external-tracking-card :creative="creative" />
      </div>
    </template>
    <template #footer>
      <div class="flex flex-wrap justify-between sm:px-3">
        <save-button
          id="creative-submit"
          :loading="isLoading"
          variant="pill"
          class="w-full text-sm custom-p-1 sm:w-auto"
        />
        <sun-button variant="pill" class="w-full text-sm custom-p-1 sm:w-auto" color="white" @click="$emit('cancel')">
          Cancel
        </sun-button>
      </div>
    </template>
  </asterix-form-modal>
</template>

<script>
import { mapActions } from 'vuex';
import CardForm from '@/components/atoms/CardForm';
import SaveButton from '@/components/atoms/SaveButton';
import FormRow from '@/components/atoms/FormRow/FormRow';
import AsterixInput from '@/components/atoms/AsterixInput';
import AsterixFormModal from '@/components/organisms/shared/AsterixFormModal';
import CreativeVideo from '@/entities/socialAudience/CreativeVideo';
import { getCreativeById, createCreative, updateCreative } from '@/services/modules/socialAudience/creative/video';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import { creative } from '@/router/private/modules/socialAudience/demand/campaign/editForm/creative';
import CreativePreview from '@/components/organisms/shared/creatives/CreativePreview';
import { CREATIVE_STATUS } from '@/model/modules/socialAudience/creative/CreativeStatus';
import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';
import ExternalTrackingCard from './ExternalTrackingCard';

export default {
  name: 'CreativeModalVideo',
  components: {
    CardForm,
    SaveButton,
    FormRow,
    AsterixInput,
    AsterixFormModal,
    CreativePreview,
    ExternalTrackingCard,
  },
  props: {
    creativeId: { type: String, default: () => null },
    insertionId: { type: String, default: () => null },
  },
  data: () => ({
    isLoading: false,
    creative: null,
    acceptedFiles: '.mp4',
    fileError: false,
    validForm: false,
    statusOptions: CREATIVE_STATUS,
    creativeTypeOptions: CreativeVideo.CREATIVE_TYPES,
    maxVideoSize: 524290000,
  }),
  computed: {
    modalTitle() {
      return !!this.creativeId && !!this.creative && this.creative?.name !== null
        ? `Edit ${this.creative.name}`
        : 'New Creative';
    },
    isCreativeTypeFile() {
      return this.creative.isCreativeTypeFile();
    },
    isCreativeTypeVast() {
      return this.creative.isCreativeTypeVast();
    },
  },
  watch: {
    creativeId: 'getCreativeById',
  },
  async created() {
    if (this.creativeId) {
      await this.getCreativeById(this.creativeId);
    } else {
      this.creative = new CreativeVideo();
    }
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
    }),
    async getCreativeById(creativeId) {
      try {
        const { data } = await getCreativeById(creativeId);
        this.creative = data;
      } catch (error) {
        if (error.code !== HTTPStatusCode.Cancel) {
          this.createToast(Toast.error(`Can't get creative.`, error.message));
        }
      }
    },
    async submitForm(value) {
      this.checkFields();

      if (!this.formValidate(value)) return;

      this.isLoading = true;
      if (this.creativeId) {
        await this.updateCreative();
      } else {
        await this.createCreative();
      }
      this.isLoading = false;
    },
    async createCreative() {
      try {
        await createCreative(this.creative, this.insertionId);
        this.createToast(Toast.success('Creative created', `The creative was created successfully.`));
      } catch (error) {
        this.createToast(Toast.error('Creative not created', error.message));
      } finally {
        await this.goToCreativesList();
      }
    },
    async updateCreative() {
      try {
        await updateCreative(this.creative);
        this.createToast(Toast.success('Creative updated', 'The creative was updated successfully'));
      } catch (error) {
        this.createToast(Toast.error('Creative not updated', error.message));
      } finally {
        await this.goToCreativesList();
      }
    },
    checkFields() {
      this.fileError = this.isCreativeTypeFile && !this.creative.file && !this.creative.urlFile;
    },
    formValidate(form) {
      if (!form.valid) return false;
      if (this.fileError) return false;

      return true;
    },
    clickFile(files) {
      this.creative.file = files?.[0];
      this.creative.urlFile = this.creative.file ? URL.createObjectURL(this.creative.file) : null;
      this.fileError = false;
    },
    deleteFile() {
      this.creative.file = null;
      this.creative.urlFile = null;
    },
    wrongFile() {
      this.fileError = true;
    },
    async goToCreativesList() {
      this.$emit('update');
      await this.$router.push(creative);
    },
    changeCreativeType(type) {
      this.creative.changeType(type);
      this.fileError = false;
    },
  },
};
</script>

<style scoped>
.content-height {
  height: 66vh;
}

::v-deep #creative-submit {
  @apply ml-0;
}

.columns-2 {
  columns: 2;
}

.list-style-inside {
  list-style: inside;
}
</style>
