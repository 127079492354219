var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.creative
    ? _c("asterix-form-modal", {
        attrs: {
          closable: "",
          "modal-class": "sm:max-w-3xl",
          title: _vm.modalTitle,
        },
        on: {
          submit: _vm.submitForm,
          keypress: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.submitForm.apply(null, arguments)
          },
          cancel: function ($event) {
            return _vm.$emit("cancel")
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "content-height overflow-auto pb-6 px-3" },
                    [
                      _c("card-form", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [_vm._v(" Info")]
                              },
                              proxy: true,
                            },
                            {
                              key: "form",
                              fn: function () {
                                return [
                                  _c("form-row", {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "left",
                                          fn: function () {
                                            return [
                                              _c(
                                                "sun-label-group",
                                                { attrs: { text: "Name" } },
                                                [
                                                  _c("asterix-input", {
                                                    attrs: {
                                                      "auto-validate": "",
                                                      maxlength: 50,
                                                      minlength: 1,
                                                      name: "name",
                                                      placeholder: "A name...",
                                                      required: "required",
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value: _vm.creative.name,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.creative,
                                                          "name",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "creative.name",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "right",
                                          fn: function () {
                                            return [
                                              _c(
                                                "sun-label-group",
                                                { attrs: { text: "Status" } },
                                                [
                                                  _c("sun-select", {
                                                    attrs: {
                                                      value:
                                                        _vm.creative.status,
                                                      options:
                                                        _vm.statusOptions,
                                                      "track-by": "value",
                                                      label: "name",
                                                      name: "status",
                                                      "close-on-select": "",
                                                      required: "required",
                                                      "text-error":
                                                        "This field is required",
                                                      "add-hex-color": "orange",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        _vm.creative.status =
                                                          $event
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      1083739806
                                    ),
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          991595849
                        ),
                      }),
                      _c("card-form", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [_vm._v(" File ")]
                              },
                              proxy: true,
                            },
                            {
                              key: "form",
                              fn: function () {
                                return [
                                  _c(
                                    "form-row",
                                    [
                                      _c(
                                        "sun-label-group",
                                        {
                                          staticClass: "w-full",
                                          attrs: { text: "Creative" },
                                        },
                                        [
                                          _c("sun-select", {
                                            attrs: {
                                              value: _vm.creative.typeCreative,
                                              options: _vm.creativeTypeOptions,
                                              "track-by": "id",
                                              label: "name",
                                              name: "status",
                                              "close-on-select": "",
                                              required: "required",
                                              "text-error":
                                                "This field is required",
                                              "add-hex-color": "orange",
                                            },
                                            on: {
                                              input: _vm.changeCreativeType,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.isCreativeTypeFile
                                    ? _c(
                                        "form-row",
                                        [
                                          _c("sun-alert", {
                                            attrs: {
                                              dismissible: false,
                                              variant: "info",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "description",
                                                  fn: function () {
                                                    return [
                                                      _vm._v(
                                                        " Please provide a video file to configure the ad. The maximum size for uploaded file is 500Mb. "
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              false,
                                              89170196
                                            ),
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.isCreativeTypeFile
                                    ? _c(
                                        "form-row",
                                        [
                                          _c(
                                            "sun-label-group",
                                            {
                                              staticClass: "w-full",
                                              attrs: { text: "File" },
                                            },
                                            [
                                              _c("sun-file-uploader", {
                                                attrs: {
                                                  accept: _vm.acceptedFiles,
                                                  "max-size": _vm.maxVideoSize,
                                                },
                                                on: {
                                                  change: _vm.clickFile,
                                                  reset: _vm.deleteFile,
                                                  "delete-file": _vm.deleteFile,
                                                  error: _vm.wrongFile,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.fileError
                                    ? _c("SunErrorText", {
                                        attrs: {
                                          "show-error": "",
                                          "text-error": "Invalid file",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.creative.urlFile
                                    ? _c(
                                        "creative-preview",
                                        { staticClass: "mt-4 mb-4" },
                                        [
                                          _c(
                                            "video",
                                            {
                                              attrs: {
                                                width: "320",
                                                height: "240",
                                                controls: "",
                                              },
                                            },
                                            [
                                              _c("source", {
                                                attrs: {
                                                  src: _vm.creative.urlFile,
                                                  type: "video/mp4",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.isCreativeTypeFile
                                    ? _c(
                                        "form-row",
                                        [
                                          _c(
                                            "sun-label-group",
                                            {
                                              staticClass: "w-full",
                                              attrs: {
                                                text: "Destination url",
                                              },
                                            },
                                            [
                                              _c("asterix-input", {
                                                attrs: {
                                                  id: "destination-url",
                                                  type: "url",
                                                  name: "destinationUrl",
                                                  "validate-on-blur": "",
                                                  placeholder: "A url...",
                                                  required: "required",
                                                },
                                                model: {
                                                  value:
                                                    _vm.creative.destinationUrl,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.creative,
                                                      "destinationUrl",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "creative.destinationUrl",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.isCreativeTypeVast
                                    ? _c(
                                        "form-row",
                                        [
                                          _c("sun-alert", {
                                            attrs: {
                                              dismissible: false,
                                              variant: "info",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "description",
                                                  fn: function () {
                                                    return [
                                                      _vm._v(
                                                        " Please configure a URL to an external ad server to enable 3rd party ad serving. The URL must lead to a valid VAST 2.0 XML response. "
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              false,
                                              2707378707
                                            ),
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.isCreativeTypeVast
                                    ? _c(
                                        "form-row",
                                        [
                                          _c(
                                            "sun-label-group",
                                            {
                                              staticClass: "w-full",
                                              attrs: {
                                                text: "3rd  Party Ad URL (VAST)",
                                              },
                                            },
                                            [
                                              _c("asterix-input", {
                                                attrs: {
                                                  id: "vast",
                                                  type: "url",
                                                  name: "vast",
                                                  "validate-on-blur": "",
                                                  placeholder: "A vast...",
                                                  required: "required",
                                                },
                                                model: {
                                                  value: _vm.creative.vast,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.creative,
                                                      "vast",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "creative.vast",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2946432186
                        ),
                      }),
                      _c("external-tracking-card", {
                        attrs: { creative: _vm.creative },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "flex flex-wrap justify-between sm:px-3" },
                    [
                      _c("save-button", {
                        staticClass: "w-full text-sm custom-p-1 sm:w-auto",
                        attrs: {
                          id: "creative-submit",
                          loading: _vm.isLoading,
                          variant: "pill",
                        },
                      }),
                      _c(
                        "sun-button",
                        {
                          staticClass: "w-full text-sm custom-p-1 sm:w-auto",
                          attrs: { variant: "pill", color: "white" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("cancel")
                            },
                          },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          3779382283
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }