var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("card-form", {
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [_vm._v(" External tracking ")]
        },
        proxy: true,
      },
      {
        key: "form",
        fn: function () {
          return [
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.addItem.apply(null, arguments)
                  },
                },
              },
              [
                _c("form-row", {
                  scopedSlots: _vm._u([
                    {
                      key: "left",
                      fn: function () {
                        return [
                          _c(
                            "sun-label-group",
                            { attrs: { text: "Type" } },
                            [
                              _c("sun-select", {
                                attrs: {
                                  value: _vm.externalTrackingType,
                                  options: _vm.externalTrackingTypes,
                                  "track-by": "id",
                                  label: "name",
                                  name: "externalTrackingType",
                                  "close-on-select": "",
                                  "text-error": "This field is required",
                                  "add-hex-color": "orange",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.externalTrackingType = $event
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "right",
                      fn: function () {
                        return [
                          _c(
                            "sun-label-group",
                            { attrs: { text: "Url" } },
                            [
                              _c("asterix-input", {
                                attrs: {
                                  id: "creative-url",
                                  type: "text",
                                  name: "url",
                                  placeholder: "A url...",
                                },
                                model: {
                                  value: _vm.externalTrackingValue,
                                  callback: function ($$v) {
                                    _vm.externalTrackingValue = $$v
                                  },
                                  expression: "externalTrackingValue",
                                },
                              }),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "focus:outline-none rounded-full hover:text-gray-600 flex cursor-pointer ml-4",
                                },
                                [_c("add-svg", { staticClass: "w-4 mx-auto" })],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm.creative.externalTracking.length > 0
              ? _c(
                  "div",
                  { ref: "externalTrackingValues", staticClass: "mt-16" },
                  [
                    _c(
                      "label",
                      {
                        staticClass:
                          "font-bold text-left text-sm mr-1 text-gray-700",
                      },
                      [_vm._v(" Values ")]
                    ),
                    _vm._l(
                      _vm.creative.externalTracking,
                      function (externalTracking, index) {
                        return _c("form-row", {
                          key: index,
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "left",
                                fn: function () {
                                  return [
                                    _c("sun-select", {
                                      attrs: {
                                        value:
                                          _vm.creative.externalTracking[index]
                                            .event,
                                        options: _vm.externalTrackingTypes,
                                        "track-by": "id",
                                        label: "name",
                                        name: "externalTrackingType",
                                        "close-on-select": "",
                                        "text-error": "This field is required",
                                        "add-hex-color": "orange",
                                      },
                                      on: {
                                        input: function ($event) {
                                          _vm.creative.externalTracking[
                                            index
                                          ].type = $event
                                        },
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "right",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "flex" },
                                      [
                                        _c("asterix-input", {
                                          attrs: {
                                            id: "creative-url",
                                            type: "url",
                                            name: "url",
                                            "validate-on-blur": "",
                                            placeholder: "A url...",
                                            required: "required",
                                          },
                                          model: {
                                            value:
                                              _vm.creative.externalTracking[
                                                index
                                              ].url,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.creative.externalTracking[
                                                  index
                                                ],
                                                "url",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "creative.externalTracking[index].url",
                                          },
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "rounded-full hover:text-gray-600 flex cursor-pointer ml-4",
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeItem(index)
                                              },
                                            },
                                          },
                                          [
                                            _c("close-svg", {
                                              staticClass: "w-4 mx-auto",
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      }
                    ),
                  ],
                  2
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }