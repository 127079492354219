<template>
  <card-form>
    <template #title> External tracking </template>
    <template #form>
      <form @submit.prevent="addItem">
        <form-row>
          <template #left>
            <sun-label-group text="Type">
              <sun-select
                :value="externalTrackingType"
                :options="externalTrackingTypes"
                track-by="id"
                label="name"
                name="externalTrackingType"
                close-on-select
                text-error="This field is required"
                add-hex-color="orange"
                @input="externalTrackingType = $event"
              />
            </sun-label-group>
          </template>
          <template #right>
            <sun-label-group text="Url">
              <asterix-input
                id="creative-url"
                v-model="externalTrackingValue"
                type="text"
                name="url"
                placeholder="A url..."
              />
              <button class="focus:outline-none rounded-full hover:text-gray-600 flex cursor-pointer ml-4">
                <add-svg class="w-4 mx-auto" />
              </button>
            </sun-label-group>
          </template>
        </form-row>
      </form>
      <div v-if="creative.externalTracking.length > 0" ref="externalTrackingValues" class="mt-16">
        <label class="font-bold text-left text-sm mr-1 text-gray-700"> Values </label>
        <form-row v-for="(externalTracking, index) in creative.externalTracking" :key="index">
          <template #left>
            <sun-select
              :value="creative.externalTracking[index].event"
              :options="externalTrackingTypes"
              track-by="id"
              label="name"
              name="externalTrackingType"
              close-on-select
              text-error="This field is required"
              add-hex-color="orange"
              @input="creative.externalTracking[index].type = $event"
            />
          </template>
          <template #right>
            <div class="flex">
              <asterix-input
                id="creative-url"
                v-model="creative.externalTracking[index].url"
                type="url"
                name="url"
                validate-on-blur
                placeholder="A url..."
                required="required"
              />
              <div class="rounded-full hover:text-gray-600 flex cursor-pointer ml-4" @click="removeItem(index)">
                <close-svg class="w-4 mx-auto" />
              </div>
            </div>
          </template>
        </form-row>
      </div>
    </template>
  </card-form>
</template>

<script>
import CardForm from '@/components/atoms/CardForm';
import FormRow from '@/components/atoms/FormRow/FormRow';
import AsterixInput from '@/components/atoms/AsterixInput';
import AddSvg from '@/components/icons/AddSvg';
import CloseSvg from '@/components/icons/CloseSvg';
import CreativeVideo from '@/entities/socialAudience/CreativeVideo';

export default {
  name: 'ExternalTrackingCard',
  components: {
    CardForm,
    FormRow,
    AsterixInput,
    AddSvg,
    CloseSvg,
  },
  props: {
    creative: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    externalTrackingType: null,
    externalTrackingValue: null,
    externalTrackingTypes: CreativeVideo.EXTERNAL_TRACKING_TYPES,
  }),
  methods: {
    addItem() {
      if (!this.externalTrackingType || !this.externalTrackingValue) return;

      this.creative.addExternalTracker(this.externalTrackingType, this.externalTrackingValue);
      this.scrollNextTick();
    },
    removeItem(index) {
      this.creative.externalTracking.splice(index, 1);
    },
    scrollNextTick() {
      this.$nextTick(() => {
        if (this.$refs.externalTrackingValues) {
          this.$refs.externalTrackingValues.scrollIntoView({ block: 'end', behavior: 'smooth' });
        }
      });
    },
  },
};
</script>
