<template>
  <div>
    <creative-modal-native
      v-if="campaignType === NATIVE_TYPE.id"
      v-bind="$attrs"
      v-on="$listeners"
    ></creative-modal-native>
    <creative-modal-video
      v-else-if="campaignType === VIDEO_TYPE.id"
      v-bind="$attrs"
      v-on="$listeners"
    ></creative-modal-video>
  </div>
</template>

<script>
import CreativeModalNative from '@/components/organisms/modules/socialAudience/campaign/form/creatives/CreativeModalNative';
import CreativeModalVideo from '@/components/organisms/modules/socialAudience/campaign/form/creatives/CreativeModalVideo';
import { NATIVE_TYPE, VIDEO_TYPE } from '@/model/modules/socialAudience/campaign/CampaignTypes';

export default {
  name: 'CreativeListHandler',
  components: {
    CreativeModalNative,
    CreativeModalVideo,
  },
  props: {
    campaignType: {
      type: String,
      default: () => null,
    },
  },
  computed: {
    NATIVE_TYPE: () => NATIVE_TYPE,
    VIDEO_TYPE: () => VIDEO_TYPE,
  },
};
</script>
