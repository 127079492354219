<template>
  <div>
    <prism-editor
      id="prism-editor"
      :value="value"
      class="prism-editor-custom block shadow appearance-none w-full border py-2 px-3 rounded border-gray-300 h-10"
      :class="{
        'border-red-400 bg-red-200': internalError,
        'outline-none shadow-outline': focus,
      }"
      :highlight="highlighter"
      @blur="onBlur($event)"
      @input="onChange($event)"
      @focus="onFocus()"
    ></prism-editor>
    <SunErrorText v-show="internalError" show-error :text-error="textError"></SunErrorText>
  </div>
</template>

<script>
import { PrismEditor } from 'vue-prism-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'vue-prism-editor/dist/prismeditor.min.css';
import 'prismjs/components/prism-markup';
import 'prismjs/themes/prism-coy.css';

export default {
  name: 'CodeEditor',
  components: {
    PrismEditor,
  },
  props: {
    internalError: {
      type: Boolean,
      default: false,
    },
    textError: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      focus: false,
    };
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages.html);
    },
    onFocus() {
      this.focus = true;
      this.$emit('focus');
    },
    onChange(event) {
      this.$emit('change', event);
    },
    onBlur(event) {
      this.focus = false;
      this.$emit('blur', event.srcElement.value);
    },
  },
};
</script>

<style>
.prism-editor-custom {
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
  min-height: inherit;
}

.prism-editor__container {
  min-height: 72px;
}
</style>
