var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.campaignType === _vm.NATIVE_TYPE.id
        ? _c(
            "creative-modal-native",
            _vm._g(
              _vm._b({}, "creative-modal-native", _vm.$attrs, false),
              _vm.$listeners
            )
          )
        : _vm.campaignType === _vm.VIDEO_TYPE.id
        ? _c(
            "creative-modal-video",
            _vm._g(
              _vm._b({}, "creative-modal-video", _vm.$attrs, false),
              _vm.$listeners
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }