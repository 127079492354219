var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("prism-editor", {
        staticClass:
          "prism-editor-custom block shadow appearance-none w-full border py-2 px-3 rounded border-gray-300 h-10",
        class: {
          "border-red-400 bg-red-200": _vm.internalError,
          "outline-none shadow-outline": _vm.focus,
        },
        attrs: {
          id: "prism-editor",
          value: _vm.value,
          highlight: _vm.highlighter,
        },
        on: {
          blur: function ($event) {
            return _vm.onBlur($event)
          },
          input: function ($event) {
            return _vm.onChange($event)
          },
          focus: function ($event) {
            return _vm.onFocus()
          },
        },
      }),
      _c("SunErrorText", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.internalError,
            expression: "internalError",
          },
        ],
        attrs: { "show-error": "", "text-error": _vm.textError },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }